/******************************************************************
Site Name:
Author:

Stylesheet: Tablet & Small Desktop Stylesheet Landscape

Needed to change the menu styling from mobile to desktop

******************************************************************/
.mobileOnly, .desktopPhone {display:none!important;}
.desktopOnly {display:inline-block!important;}
.row, body.Home #content #leftcol > div > div, body.fullWidth #leftcol > div > div {
    padding: 2em 0;
}

/*sizing*/
h1, .h1 { font-size: 2.4em; }
h2, .h2 { font-size: 2em; }
.bigger, [data-control] {font-size:1.2em;}
#message {font-size:1em;}
.button { font-size: 1.2rem; }

/*************************
Colours
*************************/

.white { color: $white; }


/*********************
LAYOUT & GRID STYLES
*********************/
.row, body.home #content #leftcol > div > div, body.fullWidth #leftcol > div > div{
    padding: 2em 0;
}

/* Reduce padding on some rows*/
#breadcrumbs .row {padding: 1em 0;}

/*Increase padding content some rows */

/* Restrict width on some rows and content areas that behave like rows*/

		
/*********************
SPRITE
*********************/
		

    #logo {
        width:235px; height: 81px;
	    background-size: 235px 81px;
	}

/*********************
HEADER STYLES
*********************/



.header {

    .row {
        position: relative;
        min-height: 110px;
        flex-wrap: no-wrap;
    }

    #logo, #accountMenu {
        transform: none;
    }

    #logo {
        left: 0%;
        top: 0.5em;
    }

    #accountMenu {
        right: 0;
        max-width: none;
        @include center(false, true);
        display:inline-grid;
        justify-items: center;

        a:first-of-type {
            margin-right: 0.5em;
        }
    }

    #mainMenu {
        display: inline-block;
        position: absolute;
        bottom: 6%;
        text-align: left;
        width: auto;
        margin-top: 0;
        @include center(false, true);
        left: 25%;
    }

    .menu-btn {
        display: none;
        right: 0%;
        left: unset;
        bottom: 0%;
        top: unset;
    }
}






/**************************
HERO - Home
***************************/

body:not(.home) #hero {

	.block {
       img {max-width:1200px;}
    }
}/*hero*/

#hero {
    
    height: 60vh;
   
}

body.Home #hero .caption {
    left: 10%;
    @include center(false, true);
    width: 45%;
}

body:not(.Home):not(.home) #hero p img {
    min-height: 625px;
    max-width: auto;
}

/*********************
NAVIGATION STYLES
*********************/

/*#touchMenu*/
.menu-btn {display:none;}


.topMenu {
    margin-left: auto;
    margin-right: auto;
    clear: none;
    float: left;
    text-align: left;

    a.toggleMenu {
        display: none;
    }

    .nav {
        position: relative;
        width: auto;
        border: 0;
        display: inline;
        margin: 0 auto;


        > li {
            display: inline-block;
            padding: 0 0.6em;

            > a {
                outline: none;
                position: relative;
                background: transparent;
                padding: 0.25em 0em;

                &:hover, &:focus {
                    background: none;
                }



                &:after {
                    content: "";
                    position: absolute;
                    width: 100%;
                    height: 2px;
                    bottom: 0;
                    left: 0;
                    background-color: $white;
                    visibility: hidden;
                    -webkit-transform: scaleX(0);
                    transform: scaleX(0);
                    @include transition(all 0.3s ease-in-out 0s);
                }

                &:hover:after, &.activerootmenulink:after {
                    visibility: visible;
                    -webkit-transform: scaleX(1);
                    transform: scaleX(1);
                }
            }
            /*Prominent contact link*/
            &:first-child {
                display: none;
            }
        }

        li {
            position: relative;

            a {
                padding: 0.25em 0em;
                background: none;
                border-bottom: none;


                &.parent:before {
                   
                        content: "\f107";
                        font-family: "Font Awesome 6 Pro";
                        font-weight: 300;
                        display: block;
                        float: right;
                        font-size: large;
                        padding-right: 0;
                        line-height: 1.2;
                        padding-top: 0.25em;
                        margin-left: 0.25em;
                
                }
            }
            /* highlight current page */
            a.activerootmenulink {
            }

            &:first-child {
            }

            &:last-child {
            }
            /*
			plan your menus and drop-downs wisely.
			*/
            ul,
            ul.sub-menu,
            ul.children {
                position: absolute;
                z-index: 9999;
                left: -9999px;
                border-top: 2px solid $color-primary;

                li {

                    a {
                        padding: 0.5em 0.5em;
                        display: block;
                        width: 250px;
                        border-top: none;
                        border-radius: 0;
                        margin-right: 0;


                        &:hover,
                        &:focus {
                            border-top: none;
                        }

                        &:link {
                        }
                    }

                    &:first-child {
                    }

                    &:last-child {

                        a {
                        }
                    }
                    /*
					if you need to go deeper, go nuts
					just remember deeper menus suck
					for usability.
					*/
                    ul {
                        border-top: none;
                    }
                }
            }
            /* showing sub-menus */
            &:hover ul {
            }
        }
        /* end .menu ul li */
    }
    /* end .nav */
} /* end .topMenu */

.topMenu .nav > li > a > i.far.fa-chevron-down {
    float: right;
    margin-left: 10px;
    margin-top: 10px;
    font-size: smaller;
}

.clearHeader .topMenu .nav li a.activerootmenulink {color: $white;}

.nav > li.hover > ul {
    left: 0;
}
.nav li li ul { left: -9999px; z-index: 99; position:absolute; }
.nav li li.hover ul { left: 100%; top: 0; z-index: 99; position:absolute; }

/* active state on home - not sure where else to put this for now*/


    .darkHeader .topMenu .nav > li > a:after {background-color: $color-primary; @include transition(all 0.3s ease-in-out 0s);}

    .nav > li.hover > ul {
        left: 0;
    }

    .nav li li ul {
        left: -9999px;
        z-index: 99;
        position: absolute;
    }

    .nav li li.hover ul {
        left: 100%;
        top: 0;
        z-index: 99;
        position: absolute;
    }





/*********************
CONTENT STYLES
*********************/

.col-2 {
    > div, > li {
        grid-column: span 1;
    }
}

/*********************
BLOCK LAYOUT
**********************/	


.columnlayout {	
	> div, > li, > article {width: calc(33% - 1em);}
    article.newsBlock {width: 25%; }
}
#gallery li {width: 25%;}



/*********************
FOOTER STYLES
*********************/

body.Template-2 {
    #solarAssessment {
        .respond-form {
            padding-top: 1em;
        }
    }

    #footer {
        .last {
            text-align: right;
        }
    }
}

     body.Template-3 {
        #footer {
            .last {text-align:right;}
        }
    }

    /*Bottom Blocks*/

#solarAssessment .block {
    margin-bottom: 2em;
    margin: 0 auto;
}

    /*layout needed for list items */
#solarAssessment #block_26 {

    ul {

        li {
            display: inline-block;
            width: 48%;
            margin-bottom: 0.5em;
        }
    }
}

/* ================================================ */
/* SLIDESHOWS */
/* ================================================ */  

/*Full width slider */
body.Home #hero {
	overflow:hidden;
	.slick-slider { }
}

ul.responsive3 {
	max-height: none;
	.slick-list {
			
		.slick-track {
				
			.slick-slide {
				/*height:500px;*/
				
				&:before {background-color: rgba(0, 0, 0, 0.45); }
				
				img {
                        /*height: 420px;*/
                    }	
				.slideshow_caption { text-align:left; /*margin: 0 auto;padding:2em; max-width:50%;*/ }
			}
				
		}
			
	}

    .slick-slider .slick-active img{ margin-left: 0;}

    .slick-prev, .slick-next { top: 50%; }
    .slick-next { right: 0px; }
    .slick-prev { left: 0; }
    .slick-slider .slick-track, .slick-slider .slick-list { -webkit-perspective: 1000px; }
}

// HOME TEMPLATE 2
body.Template-2, body.Template-3, body.Template-4 {
   .slick-list {
			
		.slick-track {
				
			.slick-slide {
	
				.slideshow_caption { text-align:center; }
			}
				
		}
    }
}



/*************************
STICKY BACK TO TOP
*************************/
    .cd-top {
        height: 50px;
        width: 50px;
    }

/*********************
Sticky CTA
*********************/

#stickyCTA {
	 
}