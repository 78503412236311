/******************************************************************
Site Name:
Author:

Stylesheet: Grid Stylesheet

I've seperated the grid so you can swap it out easily. It's
called at the top the style.scss stylesheet.

There are a ton of grid solutions out there. You should definitely
experiment with your own. Here are some recommendations:

http://gridsetapp.com - Love this site. Responsive Grids made easy.
http://susy.oddbird.net/ - Grids using Compass. Very elegant.
http://gridpak.com/ - Create your own responsive grid.

The grid below is a combination of the 1140 grid and Twitter Boostrap. 
I liked 1140 but Boostrap's grid was way more detailed so I merged them 
together, let's see how this works out. If you want to use 1140, the original 
values are commented out on each line.

******************************************************************/

.onecol, .oneflex, .col-1   { width: 5.801104972%;  }       /* 4.85%;  } /* grid_1  */
.twocol, .twoflex { width: 14.364640883%; }       /* 13.45%; } /* grid_2  */
.threecol, .threeflex, .col-3/*, body.Home .newsBlock*/  { width: 22.928176794%; }       /* 22.05%; } /* grid_3  */
.fourcol, .fourflex, .col-4    { width: 31.491712705%; }       /* 30.75%; } /* grid_4  */
.fivecol, .fiveflex, .col-5   { width: 40.055248616%; }       /* 39.45%; } /* grid_5  */
.sixcol, .sixflex, .col-6    { width: 48.618784527%; }       /* 48%;    } /* grid_6  */
.sevencol, .sevenflex, .col-7  { width: 57.182320438000005%; } /* 56.75%; } /* grid_7  */
.eightcol, .eightflex, .col-8  { width: 65.74585634900001%; }  /* 65.4%;  } /* grid_8  */
.ninecol, .nineflex, .col-9   { width: 74.30939226%; }        /* 74.05%; } /* grid_9  */
.tencol, .tenflex, .col-10   { width: 82.87292817100001%; }  /* 82.7%;  } /* grid_10 */
.elevencol, .elevenflex, .col-11 { width: 91.436464082%; }       /* 91.35%; } /* grid_11 */
.twelvecol, .twelveflex, .col-12 { width: 99.999999993%; }       /* 100%;   } /* grid_12 */

// layout & column defaults
.onecol, .twocol, .threecol, .fourcol, .fivecol, .sixcol, .sevencol, .eightcol, .ninecol, .tencol, .elevencol, .twelvecol {
  position: relative;
  float: left;
  margin-left: 2.762430939%;
}

.first {
  margin-left: 0;
}

.last {
  float: right;
}


/* Flex grid to be used as needed
******************************************************************/

.flexWrap, .aligner {
	display: -webkit-flex;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	height: 100%;

	&.reverse {
		flex-direction: row-reverse;
	}

	.oneflex, .twoflex, .threeflex, .fourflex, .fiveflex, .sixflex, .sevenflex, .eightflex, .nineflex, .tenflex, .elevenflex, .twelveflex, > h3, > p /*, body.Home .newsBlock, .first, .last*/ {
		flex: 1 1 auto;
	}

	.oneflex, .twoflex, .threeflex, .fourflex, .fiveflex, .sixflex, .sevenflex, .eightflex, .nineflex, .tenflex, .elevenflex, .twelveflex, .col-1, .col-2, .col-3, .col-4, .col-5, /*.col-6, */ .col-7, .col-8, .col-9, .col-10, .col-11, .col-12 {
		margin-left: 2.762430939%;
	}

	> h3, > p {
		width: 99.999999993%;
	}

	.first {
		margin-left: 0;
	}
	/*.last {
	  order: 2; 
	}*/
	/*use for vertical align center*/
	.aligner {
		height: auto;
		align-items: center;
		justify-content: center;
	}
}

/* STICKY BLOCKS USING FLEX */
.flexStick {
	/*stretch is default so set auto height on columns*/
	align-items: flex-start;
    /*turn on for vertical align center*/
    /*height: auto;
    align-items: center;
    justify-content: center;*/
	
	.first {order: 1;}
	.last {	order: 2;}
	
	/* tell the main element to take three times as much width as the other sidebars */
	.last{flex: 2 0px;}	

	.sticky-item {
		position: -webkit-sticky;
		position: sticky;
		flex: 1 0px;
		top: 0.5rem;
		
		@include bp(baby-screen) {
			top: 3rem;
		}
		@include bp(mama-screen) {
			top: 8rem;
		}
	}

}

/*masonry plugin
******************************************************************/
.grid:after {
  content: '';
  display: block;
  clear: both;
}

.grid-sizer,
.grid-item {
	width: 19%;
}
.gutter-sizer { width: 1%; }

.grid-item {
	min-height: 120px;
	float: left;
    margin-bottom:1%;
}



.grid-item--width2 { width:  50%; }
.grid-item--height2 { min-height: 240px; }

body:not(.admindex) .grid > br {display: none}