/******************************************************************
Site Name: 
Author: 

Stylesheet: Super Large Monitor Stylesheet

You can add some advanced styles here if you like. This kicks in
on larger screens.

******************************************************************/
h1, .h1  { line-height: 1.2;}

/*margins*/
h2, .h2, .gmb-review p{ margin: 0.5em 0;}
#solarAssessment h4, #solarAssessment .h4 {
    margin: 2em 0 1em 0;
}
.desktopPhone {display:inline-block!important;}
/*sizing*/
h1, .h1 { font-size: 3em; }
body.landing .h1 { font-size: 3em; }
h2, .h2 { font-size: 2.25em; }
#accountMenu > a:first-of-type {font-size:1.2em;}

.row, body.Home #content #leftcol > div > div, body.fullWidth #leftcol > div > div {
    padding: 3em 0;
}
	

.topMenu .nav > li > a {
    font-size: 120%;
    
}

.topMenu {
    /*list-style:none;
	float: right;
	clear: right;*/


    .nav {
        /*margin-top:0;
			position: absolute;
			width: 96%;
			z-index: 99999;*/

        li {


            a {


                &.parent:before {
                    content: "\f107";
                    font-family: "Font Awesome 6 Pro";
                    font-weight: 300;
                    display: block;
                    float: right;
                    font-size: large;
                    padding-right: 0.5em;
                    line-height: 1.2;
                }
            }
        }
    }
}

            /*********************
LAYOUT & GRID STYLES
*********************/

            .row, body.home #content #leftcol > div > div, body.fullWidth #leftcol > div > div {
                padding: 3em 0;
            }
            /*********************
HEADER STYLES
*********************/



            .header {

                #accountMenu {

                    a:first-of-type {
                        display: inline-block;
                    }
                }
            }

            @media screen and (min-width: 1240px) and (max-width: 1399px) {
                .header {
                    #mainMenu {
                        left: 26%;
                    }
                }
            }

            @media screen and (min-width: 1400px) {
                .header {
                    #mainMenu {
                        left: 22%;
                    }

                    #accountMenu {
                        display: block;
                        justify-items: center;

                        a:first-of-type {
                        }
                    }
                }
            }

            .header {
                #mainMenu {
                    @include bp(papa-screen) {
                        left: 18%;
                    }
                }
            }


            .header {

                .clearHeader {

                    > .row {
                        min-height: 120px;
                        @include transition(all 0.3s ease-in-out);
                    }

                    #logo {
                        width: 289px;
                        height: 100px;
                        background-size: 289px 100px;
                        @include transition(all 0.3s ease-in-out);
                    }
                }

                .darkHeader {

                    > .row {
                        min-height: 110px;
                        @include transition(all 0.3s ease-in-out);
                    }

                    #logo {
                        width: 265px;
                        height: 92px;
                        background-size: 265px 92px;
                        @include transition(all 0.3s ease-in-out);
                    }
                }
            }
            /**************************
HERO - Home
***************************/
            /*body.Home #hero {max-height:75vh;}*/
            body:not(.Home) #hero {
                /*height:300px;*/
                overflow: hidden;

                .block > p img {
                    min-width: 100%;
                    max-width: initial;
                    height: auto;
                }
            }

            body.Home #hero .row .slideshow_caption {
                width: 40%;
            }


            #hero .row {
                width: 60%;
            }

            #hero {
                height: 100vh;
            }


@media screen and (min-width:1941px) {
    video#myVideo {
   
        width: 100vw;
   
    }
}
            /*********************
NAVIGATION STYLES
*********************/

            .topMenu {
                .nav {
                    > li {
                        padding: 0 1em;
                    }
                }
            }
            /*********************
CONTENT STYLES
*********************/

            #content #leftcol > div > .first {
                padding: 0 2em;
            }
            /*********************
BLOCK LAYOUT
**********************/


            .columnlayout {

                > div, li {
                }
                /*Top row needs border adding seperatley to prevent double borders*/
                > div:nth-child(4), li:nth-child(4) {
                }
            }


            #solarAssessment {
                .block {
                    margin-bottom: 0em;
                }
            }
            /* LEFT BLOCKS - Mission statement*/
            #homeMission {
                .row {
                    height: 60vh;
                    display: flex;
                    align-items: center;
                }
            }
            /*Full width slider */
            ul.responsive3 {

                .slick-list {
                    .slick-track {
                        .slick-slide {

                            img {
                            }
                        }
                    }
                }
            }

